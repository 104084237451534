import { Injectable } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@node_modules/@angular/router';
import { AppConsts } from '@shared/AppConsts';
import { LocalizationService } from 'abp-ng2-module';
import { Title } from '@angular/platform-browser';

@Injectable()
export class TranslatedRouteTitleStrategy extends TitleStrategy {
    private readonly _localizationSourceName: string;

    constructor(
        private localization: LocalizationService,
        private readonly title: Title,
    ) {
        super();
        this._localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;
    }

    updateTitle(snapshot: RouterStateSnapshot): void {
        const title = this.buildTitle(snapshot);
        if (title) {
            this.title.setTitle(this._localize(title));
        }
    }

    /**
     * Localize a given key string with its value on Localization Manager
     * @param key
     * @param args
     */
    private _localize(key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, this._localizationSourceName);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, args);
    }
}
