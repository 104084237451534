import { inject, Injectable } from '@angular/core';
import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '../session/app-session.service';

import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { isArray } from 'lodash-es';
import { PermissionConsts } from '@shared/permission-consts';

@Injectable()
export class AppRouteGuard {
    constructor(
        private _permissionChecker: PermissionCheckerService,
        private _router: Router,
        private _sessionService: AppSessionService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        if (!this._sessionService.user) {
            this._router.navigate(['/account/login']);
            return false;
        }

        if (!route.data || !route.data['permission']) {
            return true;
        }

        const permissions = route.data['permission'];
        if (permissions) {
            if (isArray(permissions)) {
                const permissionChecked = permissions.some((permission: string) => this._permissionChecker.isGranted(permission));
                return permissionChecked ? true : this._router.navigate(['/fallback/no-permissions']);
            } else {
                return this._permissionChecker.isGranted(route.data['permission']) ? true : this._router.navigate(['/fallback/no-permissions']);
            }
        }

        this._router.navigate([this.selectBestRoute()]);
        return false;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        return this.canActivate(route, state);
    }

    selectBestRoute(): string {
        if (!this._sessionService.user) {
            return '/account/login';
        }

        if (this._permissionChecker.isGranted(PermissionConsts.Role_OperatorLike)) {
            return '/app/dashboard';
        }

        if (this._permissionChecker.isGranted(PermissionConsts.Service_List)) {
            return '/app/services';
        }

        if (this._permissionChecker.isGranted(PermissionConsts.User_GetAll)) {
            return '/app/users';
        }

        if (this._permissionChecker.isGranted(PermissionConsts.Role_GetAll)) {
            return '/app/roles';
        }

        return '/fallback/no-permissions';
    }
}

export const authRouteGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(AppRouteGuard).canActivate(route, state);
};
